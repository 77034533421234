import 'element-remove';
import { Controller } from 'stimulus'

export default class extends Controller {

  autoHide: number

  connect() {
    this.autoHide = window.setInterval(() => {
      this.close()
    }, 5000);
  }

  disconnect() {
    window.clearInterval(this.autoHide);
  }

  close() {
    this.element.remove()
  }
}
