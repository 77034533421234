import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['frame', 'modal', 'video']

  declare readonly frameTarget: HTMLDivElement
  declare readonly modalTarget: HTMLDivElement
  declare readonly videoTarget: HTMLVideoElement

  connect() {

  }

  disconnect() {
  }

  open(e: MouseEvent) {
    e.preventDefault()
    this.modalTarget.classList.remove("modal-closed")
    this.frameTarget.classList.remove("hidden")
    this.videoTarget.play()
  }

  close() {
    this.modalTarget.classList.add("modal-closed")
    this.videoTarget.pause()

    setTimeout(() => {
      this.frameTarget.classList.add("hidden")
    }, 300);
  }

  handleKeyup(e: KeyboardEvent) {
    if (e.code == 'Escape') {
      this.close()
    }
  }
}
