import { Controller } from 'stimulus'


export default class extends Controller {
  static targets = ['administrative', 'cse', 'adherent']

  declare readonly administrativeTarget: Element
  declare readonly cseTarget: Element
  declare readonly adherentTarget: Element

  connect() {
    $(this.element).find('.selectized_field:not(.selectized)').selectize({ plugins: ['remove_button'] })
    console.log("Hello ?")
  }

  disconnect() {
    $(this.element).find('.selectized_field.selectized').map((index, elt) => {
      elt.selectize.destroy()
    })
  }

  showPassword(evt: Event) {
    const target = evt.target as HTMLElement
    const passwordInput = target.previousElementSibling as HTMLInputElement

    if (passwordInput.getAttribute("type") === "password") {
      passwordInput.setAttribute("type", "text")
      target.innerHTML = "Masquer le mot de passe"
    } else {
      passwordInput.setAttribute("type", "password")
      target.innerHTML = "Voir le mot de passe"
    }
  }

  select(evt: Event) {
    const target = evt.target as HTMLSelectElement
    const toggledElement = this[`${target.dataset.toggle}Target`] as HTMLElement

    if (target.value.indexOf('->') >= 0) {
      toggledElement.classList.remove('hidden')
    } else {
      toggledElement.querySelector("input").value = ""
      toggledElement.classList.add('hidden')
    }
  }

  close() {
  }
}
