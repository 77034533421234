import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu']

  declare readonly menuTarget: Element
  // declare readonly hasMenuTarget: boolean

  connect() {
  }

  disconnect() {
  }

  toggle() {
    this.menuTarget.classList.toggle('opened')
  }
}
